import React from "react";
import Table, {
  InlineMenu,
  SelectColumnFilter,
  StatusIndicator,
} from "../../components/Table";
import { DateFormat } from "../../shared/Utils";
import { useNavigate, useParams } from "react-router-dom";
import ActionHandler from "../../services/data-handler";
import {
  ActionTypes,
  ACTION_HANDLER_TYPE,
  Pattern,
  EnterprisePortal,
  FORM,
} from "../../shared/common/constant";
import { base64Decode } from "@firebase/util";
import { LicenseConfig } from "../../configs/license";
import { showAlert } from "../../shared/Alert";
import { DateConfig } from "../../configs/date";
import { Modal } from "../../shared/Modal";
import Form from "../../shared/Form";
import { ModifyConfig } from "../../configs/modify";

const Licenses = () => {
  const [data, setData] = React.useState([]);
  const [companyName, setcompanyName] = React.useState("");
  const [enterpriseData, setEnterpriseData] = React.useState();
  const [checked, setChecked] = React.useState();
  const [autoUpdateModalOpen, setAutoUpdateModalOpen] = React.useState(false);
  const [maxLicensesModalOpen, setMaxLicensesModalOpen] = React.useState(false);
  const [maxLicensesValue, setMaxLicensesValue] = React.useState("");
  const [domainUpdateModalOpen, setDomainUpdateModalOpen] = React.useState(false);
  const [isBrandingImageModalOpen, setIsBrandingImageModalOpen] = React.useState(false);
  const [licenseDurationValue, setLicenseDurationValue] = React.useState("");
  const [licenseDurationModalOpen, setLicenseDurationModalOpen] = React.useState(false);
  const [isBrandingTextModalOpen, setIsBrandingTextModalOpen] = React.useState(false);
  const [isCompanyNameModalOpen, setIsCompanyNameModalOpen] = React.useState(false);
  const [isPrimaryContactModalOpen, setIsPrimaryContactModalOpen] = React.useState(false);
  const [isDomainModalOpen, setIsDomainModalOpen] = React.useState(false);
  const [domainChecked, setDomainChecked] = React.useState();
  const [isAccessCodeModalOpen, setIsAccessCodeModalOpen] = React.useState(false);
  const [isAnyAndDomainModalOpen, setIsAnyAndDomainModalOpen] = React.useState(false);
  const [brandingTextValue, setBrandingTextValue] = React.useState("");
  const [companyNameValue, setCompanyNameValue] = React.useState("");
  const [primaryContactValue, setPrimaryContactValue] = React.useState("");
  const [accessCodeValue, setAccessCodeValue] = React.useState("");
  const [isUrlCompanyNameModalOpen, setIsUrlCompanyNameModalOpen] = React.useState(false);
  const [urlCompanyValue, setUrlCompanyValue] = React.useState("");
  const [isProductModalOpen, setIsProductModalOpen] = React.useState(false);
  const [eventCategory, setEventCategory] = React.useState();

  const navigate = useNavigate();
  const { id, name } = useParams();
  const dataRef = React.useRef([]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: (row) => DateFormat(row.createdDateTime, true),
      },
      {
        Header: "Name",
        accessor: (row) => row.firstName + " " + row.lastName,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Job title",
        accessor: "jobTitle",
      },
      {
        Header: "Key",
        accessor: (row) => row.key ?? " ",
      },
      {
        Header: "Expiration date",
        accessor: (row) =>
          row.expirationDateTime
            ? new Date(row.expirationDateTime).toLocaleDateString("en-CA")
            : "",
        Cell: (props) => {
          const [isExpiryModalOpen, setIsExpiryModalOpen] =
            React.useState(false);

          const expiryActionHandler = async ({ type, data, event }) => {
            switch (type) {
              case ActionTypes.modalClose:
                if (isExpiryModalOpen) {
                  setIsExpiryModalOpen(false);
                }
                break;
              case ActionTypes.formSubmit:
                if (data) {
                  // convert string to ISO date-time
                  data.expirationDateTime = new Date(
                    data.expirationDateTime
                  ).toISOString();
                  modifyExpiryDate(props, data);
                  setIsExpiryModalOpen(false);
                }
                break;
              default:
                return;
            }
          };
          return (
            <div className="">
              <p
                className="px-2 py-6 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap"
                onClick={() => {
                  setIsExpiryModalOpen(true);
                }}
                style={{ cursor: "pointer" }}
              >
                {props.row.original.expirationDateTime
                  ? new Date(
                      props.row.original.expirationDateTime
                    ).toLocaleDateString("en-CA")
                  : ""}
              </p>
              <Modal
                isOpen={isExpiryModalOpen}
                onAction={expiryActionHandler}
                data={data}
              >
                <div className="flex items-center justify-center w-full h-full">
                  <div className="w-full max-w-sm">
                    <p className="text-xl font-bold text-gray-900">
                      {DateConfig.GenerateDate.title}
                    </p>
                    <Form
                      className={"my-6"}
                      form={DateConfig.GenerateDate}
                      onAction={expiryActionHandler}
                    />
                  </div>
                </div>
              </Modal>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: (row) => row.status.toLowerCase(),
        Filter: SelectColumnFilter,
        filter: "exact",
        Cell: StatusIndicator,
      },
      {
        id: "action-column",
        Cell: (props) => {
          const menuOptions = [...LicenseConfig.tableMenuOptions];
          return InlineMenu({
            options: [...menuOptions],
            selectedItem: (option) => onMenuOptionSelect(option, props),
          });
        },
      },
    ],
    // eslint-disable-next-line
    []
  );

  const onMenuOptionSelect = async (payload, props) => {
    const rowData = { ...props.row.original };
    switch (payload.id) {
      case 1:
        handleRevoke(rowData);
        break;
      default:
        return;
    }
  };

  const handleRevoke = async (rowData) => {
    const confirmation = window.confirm(
      `Are you sure you want to revoke this license? This cannot be reverted.`
    );
    if (confirmation === false) {
      return;
    }
    try {
      await ActionHandler(ACTION_HANDLER_TYPE.DELETE_LICENSE, {
        id: Number(id),
        licenseId: Number(rowData.id),
      });
      const enterpriseIndex = dataRef.current.findIndex(
        (enterprise) => enterprise.id === rowData.id
      );
      if (enterpriseIndex !== -1) {
        const updatedData = [...dataRef.current];
        updatedData.splice(enterpriseIndex, 1);
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Revoked enterprise.",
        });
      }
    } catch (err) {
      showAlert({
        type: ActionTypes.failAlert,
        text: "Something went wrong.",
      });
    }
  };

  const handleRemove = async (value, fieldChipValues) => {
    const confirmation = window.confirm(
      `Are you sure you want to remove ${value} from the specified domain(s)?`
    );
    if (confirmation === false) {
      return;
    }
    try {
      if (fieldChipValues.length < 1) {
        const newDomainUpdate = !enterpriseData.anyDomain;
        setDomainChecked(newDomainUpdate);
        modifyDomainUpdate(newDomainUpdate);
        setIsDomainModalOpen(false);
      } else {
        const newLicenseData = enterpriseData;
        const response = await ActionHandler(
          ACTION_HANDLER_TYPE.MODIFY_ENTERPRISE,
          {
            body: newLicenseData,
          }
        );

        if (response) {
          const enterpriseIndex = dataRef.current.findIndex(
            (enterprise) => enterprise.id === response.id
          );
          if (enterpriseIndex === -1) {
            const updatedData = [...dataRef.current];
            updatedData[enterpriseIndex] = { ...response };
            setData([...updatedData]);
            showAlert({
              type: ActionTypes.successAlert,
              text: "Domain(s) updated.",
            });
          }
        }
      }
    } catch (err) {
      showAlert({
        type: ActionTypes.failAlert,
        text: "Something went wrong.",
      });
    }
  };

  const modifyExpiryDate = async (props, data) => {
    const rowData = { ...props.row.original };
    const expirationDateTime = data.expirationDateTime;

    rowData.id = Number(rowData.id);
    rowData.expirationDateTime = expirationDateTime;

    const updateEnterpriseExpiry = {
      key: rowData.key,
      expirationDateTime: rowData.expirationDateTime,
    };

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_ENTERPRISE_EXPIRATION,
      {
        id: Number(rowData.id),
        body: updateEnterpriseExpiry,
      }
    );

    if (response) {
      const enterpriseIndex = dataRef.current.findIndex(
        (enterprise) => enterprise.id === response.id
      );
      if (enterpriseIndex !== -1) {
        const updatedData = [...dataRef.current];
        updatedData[enterpriseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "License key expiration date updated.",
        });
      }
    }
  };

  React.useEffect(() => {
    if (id >= 0) {
      getLicenseByEnterpriseId();
      if (name) {
        setcompanyName(base64Decode(name));
      }
    } else {
      navigate("/enterprise");
    }

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    dataRef.current = [...data];
  }, [data]);

  React.useEffect(() => {
    ActionHandler(ACTION_HANDLER_TYPE.GET_ENTERPRISES_DETAILS, {
      id,
    }).then((enterpriseDetails) => {
      setEnterpriseData(enterpriseDetails);
      setMaxLicensesValue(enterpriseDetails.maxLicenses);
      setLicenseDurationValue(enterpriseDetails.licenseDuration);
    });
    // eslint-disable-next-line
  }, []);

  const handleAutoUpdateSubmit = () => {
    const newAutoUpdate = !enterpriseData.autoUpdateEnabled;

    setChecked(newAutoUpdate);
    modifyAutoUpdate(newAutoUpdate, enterpriseData);
    setAutoUpdateModalOpen(false);
  };

  const handleDomainUpdateSubmit = () => {
    const newDomainUpdate = !enterpriseData.anyDomain;
    setDomainChecked(newDomainUpdate);
    setDomainUpdateModalOpen(false);

    if (newDomainUpdate === true) {
      modifyDomainUpdate(newDomainUpdate);
    } else {
      setIsAnyAndDomainModalOpen(true);
    }
  };

  const handleChange = (e) => {
    if (e.target.value === "") {
      setMaxLicensesValue(e.target.value);
    } else if (Pattern.numericRegex.test(e.target.value)) {
      setMaxLicensesValue(e.target.value);
    }
  };

  const handleChangeDuration = (e) => {
    if (e.target.value === "") {
      setLicenseDurationValue(e.target.value);
    } else if (Pattern.numericRegex.test(e.target.value)) {
      setLicenseDurationValue(e.target.value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setMaxLicensesModalOpen(true);
      setMaxLicensesValue(maxLicensesValue);
    }
  };

  const handleKeyDownDuration = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setLicenseDurationModalOpen(true);
      setLicenseDurationValue(licenseDurationValue);
    }
  };

  const handlePrimaryContactSubmit = (e) => {
    e.preventDefault();
    if (!Pattern.emailRegex.test(primaryContactValue)) {
      showAlert({
        type: ActionTypes.failAlert,
        text: "Email address is invalid, please try again.",
      });
    } else {
      setPrimaryContactValue(primaryContactValue);
      modifyPrimaryContact(primaryContactValue);
      setIsPrimaryContactModalOpen(false);
    }
  };

  const handleAccessCodeSubmit = (e) => {
    e.preventDefault();
    if (Pattern.specialCharacterRegex.test(accessCodeValue)) {
      showAlert({
        type: ActionTypes.failAlert,
        text: "Invalid access code. Access code must not contain special characters or spaces.",
      });
    } else {
      setAccessCodeValue(accessCodeValue);
      modifyAccessCode(accessCodeValue);
      setIsAccessCodeModalOpen(false);
    }
  };

  const handleUrlCompanySubmit = (e) => {
    e.preventDefault();
    if (Pattern.specialCharacterUrlRegex.test(urlCompanyValue)) {
      showAlert({
        type: ActionTypes.failAlert,
        text: "Invalid URL Company name. URL must not contain special characters or spaces.",
      });
    } else {
      setUrlCompanyValue(urlCompanyValue);
      modifyUrlCompanyName(urlCompanyValue);
      setIsUrlCompanyNameModalOpen(false);
    }
  };

  const handleEventCategory = (e) => {
    setEventCategory(e.target.value);
  };

  const handleProductTypeSubmit = (e) => {
    setEventCategory(e.target.value);
    modifyProductType(eventCategory);
    setIsProductModalOpen(false);
  };

  const getLicenseByEnterpriseId = async () => {
    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.GET_ENTERPRISES_LICENSES,
      id
    );

    response.licenses.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    setData(response.licenses);
  };

  const modifyMaxLicenses = async (maxLicensesValue) => {
    const newLicenseData = enterpriseData;
    newLicenseData.maxLicenses = Number(maxLicensesValue);

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_ENTERPRISE,
      {
        body: newLicenseData,
      }
    );

    if (response) {
      const enterpriseIndex = dataRef.current.findIndex(
        (enterprise) => enterprise.id === response.id
      );
      if (enterpriseIndex === -1) {
        const updatedData = [...dataRef.current];
        updatedData[enterpriseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Maximum Activations setting changed.",
        });
      }
    }
  };

  const modifyLicenseDuration = async (licenseDurationValue) => {
    const newLicenseData = enterpriseData;
    newLicenseData.licenseDuration = Number(licenseDurationValue);

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_ENTERPRISE,
      {
        body: newLicenseData,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.id === response.id
      );
      if (licenseIndex === -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "License Duration setting changed.",
        });
      }
    }
  };

  const modifyBrandingImage = async (newBrandingImage) => {
    const newLicenseData = enterpriseData;
    newLicenseData.brandingImage = String(newBrandingImage);

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_ENTERPRISE,
      {
        body: newLicenseData,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.id === response.id
      );
      if (licenseIndex === -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Branding image updated.",
        });
      }
    } else {
      showAlert({
        type: ActionTypes.failAlert,
        text: "Branding image update failed, please try again.",
      });
    }
  };

  const modifyBrandingText = async (newBrandingText) => {
    const newLicenseData = enterpriseData;
    newLicenseData.brandingText = String(newBrandingText);

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_ENTERPRISE,
      {
        body: newLicenseData,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.id === response.id
      );
      if (licenseIndex === -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Branding text updated.",
        });
      }
    } else {
      showAlert({
        type: ActionTypes.failAlert,
        text: "Branding text update failed, please try again.",
      });
    }
  };

  const modifyCompanyName = async (newCompanyName) => {
    const newLicenseData = enterpriseData;
    newLicenseData.companyName = String(newCompanyName);

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_ENTERPRISE,
      {
        body: newLicenseData,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.id === response.id
      );
      if (licenseIndex === -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Company name updated.",
        });
      }
    }
  };

  const modifyPrimaryContact = async (newPrimaryContact) => {
    const newLicenseData = enterpriseData;
    newLicenseData.primaryContactEmail = String(newPrimaryContact);

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_ENTERPRISE,
      {
        body: newLicenseData,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.id === response.id
      );
      if (licenseIndex === -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Primary contact email updated.",
        });
      }
    }
  };

  const modifyAutoUpdate = async (newAutoUpdate) => {
    const newLicenseData = enterpriseData;

    newLicenseData.autoUpdateEnabled = Boolean(newAutoUpdate);

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_ENTERPRISE,
      {
        body: newLicenseData,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.key === response.key
      );
      if (licenseIndex === -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Auto-Update setting changed.",
        });
      }
    }
  };

  const modifyDomainUpdate = async (newDomainUpdate) => {
    const newLicenseData = enterpriseData;

    newLicenseData.anyDomain = Boolean(newDomainUpdate);
    newLicenseData.domains = [];

    // const updateDomainUpdate = {
    //   domains: newLicenseData.domains,
    //   anyDomain: newLicenseData.anyDomain,
    // };

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_ENTERPRISE,
      {
        body: newLicenseData,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.key === response.key
      );
      if (licenseIndex === -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Any domain setting changed.",
        });
      }
    }
  };

  const modifyAnyAndDomains = async (newDomainsValue) => {
    const newLicenseData = enterpriseData;

    newLicenseData.anyDomain = !Boolean(newLicenseData.anyDomain);
    newLicenseData.domains = newDomainsValue;

    // const updateAnyAndDomains = {
    //   anyDomain: newLicenseData.anyDomain,
    //   domains: newLicenseData.domains,
    // };

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_ENTERPRISE,
      {
        body: newLicenseData,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.id === response.id
      );
      if (licenseIndex === -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Any Domain setting and Domain(s) updated.",
        });
      }
    }
    setIsAnyAndDomainModalOpen(false);
  };

  const modifyDomains = async (newDomains) => {
    const newLicenseData = enterpriseData;
    newLicenseData.domains = newDomains;

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_ENTERPRISE,
      {
        body: newLicenseData,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.id === response.id
      );
      if (licenseIndex === -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Domain(s) updated.",
        });
      }
    }
  };

  const modifyAccessCode = async (newAccessCode) => {
    const newLicenseData = enterpriseData;
    newLicenseData.accessCode = String(newAccessCode);

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_ENTERPRISE,
      {
        body: newLicenseData,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.id === response.id
      );
      if (licenseIndex === -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Access code updated.",
        });
      }
    }
  };

  const modifyUrlCompanyName = async (newUrlCompanyName) => {
    const newLicenseData = enterpriseData;
    if (
      newLicenseData.accessCode === null &&
      newLicenseData.licenseDuration === 0
    ) {
      newLicenseData.accessCode = "";
      newLicenseData.licenseDuration = 1;
    }
    newLicenseData.urlCompanyName = String(newUrlCompanyName);

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_ENTERPRISE,
      {
        body: newLicenseData,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.id === response.id
      );
      if (licenseIndex === -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Company Name URL updated.",
        });
      }
    }
  };

  const modifyProductType = async (eventCategory) => {
    const newLicenseData = enterpriseData;
    newLicenseData.desktopEnabled = Boolean(eventCategory);

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_ENTERPRISE,
      {
        body: newLicenseData,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.key === response.key
      );
      if (licenseIndex === -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Product Type setting changed.",
        });
      }
    }
  };

  const modifyDomainHandler = ({ type, data, event }) => {
    switch (type) {
      case ActionTypes.modalClose:
        if (isAnyAndDomainModalOpen) {
          setIsAnyAndDomainModalOpen(false);
        }
        break;
      case ActionTypes.formSubmit:
        if (data.domains) {
          modifyAnyAndDomains(data.domains);
          setIsAnyAndDomainModalOpen(false);
        }
        break;
      default:
        return;
    }
  };

  const modifyAddDomainHandler = ({ type, data, event }) => {
    switch (type) {
      case ActionTypes.modalClose:
        if (isDomainModalOpen) {
          setIsDomainModalOpen(false);
        }
        break;
      case ActionTypes.formSubmit:
        if (data.domains) {
          const specificDomains = enterpriseData.domains;
          const addDomain = specificDomains.concat(data.domains);
          modifyDomains(addDomain);
          setIsDomainModalOpen(false);
        }
        break;
      default:
        return;
    }
  };

  const actionHandler = async ({ type, data, event }) => {
    switch (type) {
      case ActionTypes.modalClose:
        if (isBrandingImageModalOpen) {
          setIsBrandingImageModalOpen(false);
        }
        break;
      case ActionTypes.formSubmit:
        if (data.brandingImage) {
          modifyBrandingImage(data.brandingImage);
          setIsBrandingImageModalOpen(false);
        }
        break;
      default:
        return;
    }
  };

  const removeChipValue = (value, index, e) => {
    const fieldChipValues = enterpriseData.domains;

    if (fieldChipValues && fieldChipValues.length > index) {
      fieldChipValues.splice(index, 1);
      handleRemove(value, fieldChipValues);
    }
  };

  React.useEffect(() => {
    document.body.style.overflow = "auto";

    return () => {
      document.body.style.overflow = "hidden";
    };
  }, []);

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden">
      <main>
        {enterpriseData !== undefined && (
          <div className="py-2 mt-6">
            <div className="px-6 mx-auto">
              <div className="space-y-5 sm:space-y-6">
                <div className="grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
                  <div className="bg-white border border-gray-200 rounded">
                    <div className="px-5 py-4">
                      <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                        Branding Image
                      </p>

                      <div className="mt-3">
                        <p
                          onClick={() => {
                            setIsBrandingImageModalOpen(true);
                          }}
                          className="cursor-pointer text-sm font-medium text-gray-900"
                        >
                          {enterpriseData.brandingImage ? (
                            <img
                              src={enterpriseData.brandingImage}
                              // onError={(event) => {
                              //   event.target.src = {  };
                              //   event.onerror = null;
                              // }}
                              alt="logo"
                              className="cursor-pointer w-40 justify-center mx-auto"
                              onClick={() => {
                                setIsBrandingImageModalOpen(true);
                              }}
                            ></img>
                          ) : (
                            <span
                              onClick={() => {
                                setIsBrandingImageModalOpen(true);
                              }}
                            >
                              No branding image specified.
                            </span>
                          )}
                        </p>
                      </div>

                      <Modal
                        isOpen={isBrandingImageModalOpen}
                        onAction={() => {
                          setIsBrandingImageModalOpen(true);
                        }}
                        data={data}
                      >
                        <div className="flex items-center justify-center w-full h-full">
                          <div className="w-full max-w-sm">
                            <p className="text-xl font-bold text-gray-900">
                              {ModifyConfig.ModifyFile.title}
                            </p>
                            <Form
                              className={"my-6"}
                              form={ModifyConfig.ModifyFile}
                              onAction={actionHandler}
                            />
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
                  <div className="bg-white border border-gray-200 rounded">
                    <div className="px-5 py-4">
                      <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                        Branding Text
                      </p>
                      <div className="flex items-center justify-between mt-3">
                        <p
                          onClick={(e) => {
                            setBrandingTextValue(e.target.textContent);
                            setIsBrandingTextModalOpen(true);
                          }}
                          className="cursor-pointer text-sm font-medium text-gray-900"
                        >
                          {enterpriseData.brandingText
                            ? enterpriseData.brandingText
                            : "No branding text specified."}
                        </p>
                      </div>
                      <Modal
                        isOpen={isBrandingTextModalOpen}
                        onAction={() => {
                          setIsBrandingTextModalOpen(true);
                        }}
                        data={data}
                      >
                        <div className="flex items-center justify-center w-full h-full">
                          <div className="w-full max-w-sm">
                            <p className="text-xl font-bold text-gray-900">
                              {ModifyConfig.ModifyText.title}
                            </p>
                            <div className="my-6">
                              <label
                                htmlFor=""
                                className="text-sm font-bold text-gray-900"
                              >
                                {ModifyConfig.ModifyText.label}
                              </label>
                              <div className="mt-2">
                                <textarea
                                  required={false}
                                  type={FORM.TYPES.TEXT}
                                  name="brandingText"
                                  placeholder=""
                                  defaultValue={enterpriseData.brandingText}
                                  rows="5"
                                  maxLength="1000"
                                  onChange={(e) => {
                                    setBrandingTextValue(e.target.value);
                                  }}
                                  className="block w-full px-4 py-3 placeholder-gray-500 border -gray-300 rounded focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600"
                                />
                              </div>
                            </div>

                            <div className="mt-8 space-x-5">
                              <div className="flex items-center justify-end mt-5 space-x-4">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setIsBrandingTextModalOpen(false);
                                  }}
                                  className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setBrandingTextValue(brandingTextValue);
                                    modifyBrandingText(brandingTextValue);
                                    setIsBrandingTextModalOpen(false);
                                  }}
                                  className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>

                  <div className="bg-white border border-gray-200 rounded">
                    <div className="px-5 py-4">
                      <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                        Company Name
                      </p>
                      <div className="flex items-center justify-between mt-3">
                        <p
                          onClick={(e) => {
                            setCompanyNameValue(e.target.textContent);
                            setIsCompanyNameModalOpen(true);
                          }}
                          className="cursor-pointer text-lg font-bold text-gray-900"
                        >
                          {enterpriseData.companyName}
                        </p>
                      </div>

                      <Modal
                        isOpen={isCompanyNameModalOpen}
                        onAction={() => {
                          setIsCompanyNameModalOpen(true);
                        }}
                        data={data}
                      >
                        <div className="flex items-center justify-center w-full h-full">
                          <div className="w-full max-w-sm">
                            <p className="text-xl font-bold text-gray-900">
                              {ModifyConfig.ModifyName.title}
                            </p>

                            {/* <Form
                              className={"my-6"}
                              form={ModifyConfig.ModifyName}
                              onAction={actionHandler}
                              
                            /> */}

                            <div className="my-6">
                              <label
                                htmlFor=""
                                className="text-sm font-bold text-gray-900"
                              >
                                {ModifyConfig.ModifyName.label}
                              </label>
                              <div className="mt-2">
                                <input
                                  required={false}
                                  type={FORM.TYPES.TEXT}
                                  name="companyName"
                                  placeholder=""
                                  defaultValue={enterpriseData.companyName}
                                  onChange={(e) => {
                                    setCompanyNameValue(e.target.value);
                                  }}
                                  className="block w-full px-4 py-3 placeholder-gray-500 border -gray-300 rounded focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600"
                                />
                              </div>
                            </div>
                            <div className="mt-8 space-x-5">
                              <div className="flex items-center justify-end mt-5 space-x-4">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setIsCompanyNameModalOpen(false);
                                  }}
                                  className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setCompanyNameValue(companyNameValue);
                                    modifyCompanyName(companyNameValue);
                                    setIsCompanyNameModalOpen(false);
                                  }}
                                  className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>

                  <div className="bg-white border border-gray-200 rounded">
                    <div className="px-5 py-4">
                      <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                        Product Type
                      </p>
                      <div className="flex items-center justify-between mt-3">
                        <p
                          className="text-xl font-bold text-gray-900"
                          onClick={() => {
                            setIsProductModalOpen(true);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {enterpriseData.desktopEnabled === true
                            ? "Pro"
                            : enterpriseData.desktopEnabled === false
                            ? " Lite "
                            : " - "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <Modal
                    isOpen={isProductModalOpen}
                    onAction={() => {
                      setIsProductModalOpen(true);
                    }}
                    data={data}
                  >
                    <div className="flex items-center justify-center w-full h-full">
                      <div className="w-full max-w-sm">
                        <p className="text-xl font-bold text-gray-900">
                          Product Type
                        </p>
                        <div className={"my-6"}>
                          <select
                            value={eventCategory}
                            onChange={handleEventCategory}
                          >
                            <option value="false">Lite</option>
                            <option value="true">Pro</option>
                          </select>
                        </div>
                        <div className="mt-8 space-x-5">
                          <button
                            type="button"
                            onClick={() => {
                              setIsProductModalOpen(false);
                            }}
                            className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            onClick={handleProductTypeSubmit}
                            className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </Modal>

                  <div className="bg-white border border-gray-200 rounded">
                    <div className="px-5 py-4">
                      <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                        Primary contact
                      </p>
                      <div className="flex items-center justify-between mt-3">
                        <p
                          onClick={(e) => {
                            setPrimaryContactValue(e.target.textContent);
                            setIsPrimaryContactModalOpen(true);
                          }}
                          className="cursor-pointer text-md font-bold text-gray-900"
                        >
                          {enterpriseData.primaryContactEmail}
                        </p>
                      </div>

                      <Modal
                        isOpen={isPrimaryContactModalOpen}
                        onAction={() => {
                          setIsPrimaryContactModalOpen(true);
                        }}
                        data={data}
                      >
                        <div className="flex items-center justify-center w-full h-full">
                          <div className="w-full max-w-sm">
                            <p className="text-xl font-bold text-gray-900">
                              {ModifyConfig.ModifyContact.title}
                            </p>
                            <div className="my-6">
                              <label
                                htmlFor=""
                                className="text-sm font-bold text-gray-900"
                              >
                                {ModifyConfig.ModifyContact.label}
                              </label>
                              <div className="mt-2">
                                <input
                                  required={false}
                                  type={FORM.TYPES.EMAIL}
                                  name="primaryContactEmail"
                                  placeholder=""
                                  defaultValue={
                                    enterpriseData.primaryContactEmail
                                  }
                                  onChange={(e) => {
                                    setPrimaryContactValue(e.target.value);
                                  }}
                                  className="block w-full px-4 py-3 placeholder-gray-500 border -gray-300 rounded focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600"
                                />
                              </div>
                            </div>
                            <div className="mt-8 space-x-5">
                              <div className="flex items-center justify-end mt-5 space-x-4">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setIsPrimaryContactModalOpen(false);
                                  }}
                                  className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                >
                                  Cancel
                                </button>
                                <button
                                  type={FORM.TYPES.SUBMIT}
                                  onClick={handlePrimaryContactSubmit}
                                  className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>

                  <div className="bg-white border border-gray-200 rounded">
                    <div className="px-5 py-4">
                      <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                        License Duration (No. of days)
                      </p>
                      <div className="flex items-center justify-between mt-3">
                        <input
                          type="text"
                          value={licenseDurationValue}
                          minLength="1"
                          maxLength="4"
                          onKeyDown={handleKeyDownDuration}
                          onChange={handleChangeDuration}
                          className={
                            "block w-1/3 px-2 py-1 placeholder-gray-500 border -gray-300 rounded focus:ring-indigo-600 focus:border-indigo-600 sm:text-lg font-bold text-gray-900 caret-indigo-600"
                          }
                        />
                        <Modal isOpen={licenseDurationModalOpen}>
                          <div className="text-center my-5">
                            <p>
                              Are you sure you want to change the License
                              Duration setting for {enterpriseData.companyName}?
                            </p>

                            <div className="mt-8 space-x-5">
                              <button
                                type="button"
                                onClick={() => {
                                  setLicenseDurationValue(
                                    enterpriseData.licenseDuration
                                  );
                                  setLicenseDurationModalOpen(false);
                                }}
                                className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setLicenseDurationValue(licenseDurationValue);
                                  modifyLicenseDuration(licenseDurationValue);
                                  setLicenseDurationModalOpen(false);
                                }}
                                className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </Modal>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white border border-gray-200 rounded">
                    <div className="px-5 py-4">
                      <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                        Auto Update
                      </p>
                      <div className="flex items-center justify-between mt-3">
                        <input
                          type="checkbox"
                          checked={
                            checked ? checked : enterpriseData.autoUpdateEnabled
                          }
                          onChange={(e) => {
                            e.preventDefault();
                            setAutoUpdateModalOpen(true);
                          }}
                          className={
                            "cursor-pointer flex gap-x-2 w-7 h-7 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <Modal isOpen={autoUpdateModalOpen}>
                    <div className="text-center my-5">
                      <p>
                        Are you sure you want to change the Auto-Update setting
                        for {enterpriseData.companyName}?
                      </p>
                      <div className="mt-8 space-x-5">
                        <button
                          type="button"
                          onClick={() => {
                            setChecked(enterpriseData.autoUpdateEnabled);
                            setAutoUpdateModalOpen(false);
                          }}
                          className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={handleAutoUpdateSubmit}
                          className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </Modal>

                  <div className="bg-white border border-gray-200 rounded">
                    <div className="px-5 py-4">
                      <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                        Current Activations
                      </p>
                      <div className="flex items-center justify-between mt-3">
                        <p className="text-xl font-bold text-gray-900">
                          {enterpriseData.currentActivations}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white border border-gray-200 rounded">
                    <div className="px-5 py-4">
                      <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                        Maximum Activations
                      </p>
                      <div className="flex items-center justify-between mt-3">
                        <input
                          type="text"
                          value={maxLicensesValue}
                          minLength="1"
                          maxLength="5"
                          onKeyDown={handleKeyDown}
                          onChange={handleChange}
                          className={
                            "block w-1/3 px-2 py-1 placeholder-gray-500 border -gray-300 rounded focus:ring-indigo-600 focus:border-indigo-600 sm:text-lg font-bold text-gray-900 caret-indigo-600"
                          }
                        />
                        <Modal isOpen={maxLicensesModalOpen}>
                          <div className="text-center my-5">
                            <p>
                              Are you sure you want to change the Maximum
                              Licenses setting for {enterpriseData.companyName}?
                            </p>

                            <div className="mt-8 space-x-5">
                              <button
                                type="button"
                                onClick={() => {
                                  setMaxLicensesValue(
                                    enterpriseData.maxLicenses
                                  );
                                  setMaxLicensesModalOpen(false);
                                }}
                                className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setMaxLicensesValue(maxLicensesValue);
                                  modifyMaxLicenses(maxLicensesValue);
                                  setMaxLicensesModalOpen(false);
                                }}
                                className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
                  <div className="bg-white border border-gray-200 rounded">
                    <div className="px-5 py-4">
                      <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                        Any Domain
                      </p>
                      <div className="flex items-center justify-between mt-3">
                        <input
                          type="checkbox"
                          checked={
                            domainChecked
                              ? domainChecked
                              : enterpriseData.anyDomain
                          }
                          onChange={(e) => {
                            e.preventDefault();
                            setDomainUpdateModalOpen(true);
                          }}
                          className={
                            "cursor-pointer flex gap-x-2 w-7 h-7 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <Modal isOpen={domainUpdateModalOpen}>
                    <div className="text-center my-5">
                      <p>
                        Are you sure you want to change the Any Domain setting
                        for {enterpriseData.companyName}?
                      </p>
                      <p className="mt-4">
                        This would overwrite specified domain(s)
                      </p>
                      <div className="mt-8 space-x-5">
                        <button
                          type="button"
                          onClick={() => {
                            setDomainChecked(enterpriseData.anyDomain);
                            setDomainUpdateModalOpen(false);
                          }}
                          className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={handleDomainUpdateSubmit}
                          className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </Modal>

                  <Modal
                    isOpen={isAnyAndDomainModalOpen}
                    onAction={() => {
                      setIsAnyAndDomainModalOpen(true);
                    }}
                    data={data}
                  >
                    <div className="flex items-center justify-center w-full h-full">
                      <div className="w-full max-w-sm">
                        <p className="text-xl font-bold text-gray-900">
                          {ModifyConfig.ModifyDomain.title}
                        </p>
                        <Form
                          className={"my-6"}
                          form={ModifyConfig.ModifyDomain}
                          onAction={modifyDomainHandler}
                        />
                      </div>
                    </div>
                  </Modal>

                  <div className="grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-1 lg:col-span-2">
                    <div className="bg-white border border-gray-200 rounded">
                      <div className="px-5 py-4">
                        <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                          Domain(s)
                        </p>

                        <div className="flex items-center justify-between mt-3">
                          <fieldset
                            disabled={enterpriseData.anyDomain}
                            onClick={() => {
                              setIsDomainModalOpen(true);
                            }}
                            className="flex flex-wrap cursor-pointer disabled:pointer-events-none"
                          >
                            {enterpriseData.domains.length > 0
                              ? enterpriseData.domains.map((value, index) => {
                                  return (
                                    <span
                                      key={index}
                                      onClick={() => {
                                        setIsDomainModalOpen(true);
                                      }}
                                      className="flex flex-wrap pl-2 pr-2 py-1 m-1 justify-between items-center text-xs font-medium font-semibold rounded cursor-pointer bg-gray-200 text-black-100"
                                    >
                                      {value}
                                    </span>
                                  );
                                })
                              : "No domain(s) specified."}
                          </fieldset>
                        </div>

                        <Modal
                          isOpen={isDomainModalOpen}
                          onAction={() => {
                            setIsDomainModalOpen(true);
                          }}
                          data={data}
                        >
                          <div className="flex items-center justify-center w-full h-full">
                            <div className="w-full max-w-sm">
                              <p className="text-xl font-bold text-gray-900">
                                {ModifyConfig.ModifyDomain.title}
                              </p>
                              <Form
                                className={"my-6"}
                                form={ModifyConfig.ModifyDomain}
                                onAction={modifyAddDomainHandler}
                              />

                              <div className="text-sm">Existing domain(s):</div>
                              <div className="px-2 my-2 flex flex-wrap">
                                {enterpriseData.domains.length > 0
                                  ? enterpriseData.domains.map(
                                      (value, index) => {
                                        return (
                                          <span
                                            key={index}
                                            className="flex flex-wrap pl-2 pr-2 py-1 m-1 justify-between items-center text-xs font-medium font-semibold rounded cursor-pointer bg-gray-200 text-black-100"
                                          >
                                            {value}
                                            <svg
                                              onClick={() =>
                                                removeChipValue(value, index)
                                              }
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="h-5 w-5 ml-3 hover:text-gray-600"
                                              viewBox="0 0 20 20"
                                              fill="currentColor"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </span>
                                        );
                                      }
                                    )
                                  : "No domain(s) specified"}
                              </div>
                            </div>
                          </div>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
                  <div className="bg-white border border-gray-200 rounded">
                    <div className="px-5 py-4">
                      <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                        URL Company Name
                      </p>
                      <div className="flex items-center justify-between mt-3">
                        <p
                          onClick={(e) => {
                            setUrlCompanyValue(e.target.textContent);
                            setIsUrlCompanyNameModalOpen(true);
                          }}
                          className="cursor-pointer text-md font-bold text-gray-900"
                        >
                          {enterpriseData.urlCompanyName ? (
                            enterpriseData.urlCompanyName
                          ) : (
                            <span className="text-sm font-medium text-gray-900">
                              No URL Company name specified.
                            </span>
                          )}
                        </p>
                      </div>

                      <Modal
                        isOpen={isUrlCompanyNameModalOpen}
                        onAction={() => {
                          setIsUrlCompanyNameModalOpen(true);
                        }}
                        data={data}
                      >
                        <div className="flex items-center justify-center w-full h-full">
                          <div className="w-full max-w-sm">
                            <p className="text-xl font-bold text-gray-900">
                              {ModifyConfig.ModifyUrlCompany.title}
                            </p>
                            <div className="my-6">
                              <label
                                htmlFor=""
                                className="text-sm font-bold text-gray-900"
                              >
                                {ModifyConfig.ModifyUrlCompany.label}
                              </label>
                              <div className="mt-2">
                                <input
                                  required={false}
                                  type={FORM.TYPES.TEXT}
                                  name="urlCompanyName"
                                  placeholder=""
                                  defaultValue={enterpriseData.urlCompanyName}
                                  onChange={(e) => {
                                    setUrlCompanyValue(e.target.value);
                                  }}
                                  className="block w-full px-4 py-3 placeholder-gray-500 border -gray-300 rounded focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600"
                                />
                              </div>
                            </div>
                            <p className="text-sm text-gray-900 my-6">
                              This must be an alphanumeric name and excludes
                              ".com". For example ,"vodium".
                            </p>
                            <p className="text-sm text-gray-900 my-6">
                              Dashes "-" or underscores "_" may also be used.
                              For example ,"vodium-com".
                            </p>
                            <div className="mt-8 space-x-5">
                              <div className="flex items-center justify-end mt-5 space-x-4">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setIsUrlCompanyNameModalOpen(false);
                                  }}
                                  className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  onClick={handleUrlCompanySubmit}
                                  className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>

                  <div className="bg-white border border-gray-200 rounded">
                    <div className="px-5 py-4">
                      <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                        Access Code
                      </p>
                      <div className="flex items-center justify-between mt-3">
                        <p
                          onClick={(e) => {
                            setAccessCodeValue(e.target.textContent);
                            setIsAccessCodeModalOpen(true);
                          }}
                          className="cursor-pointer text-md font-bold text-gray-900"
                        >
                          {enterpriseData.accessCode ? (
                            enterpriseData.accessCode
                          ) : (
                            <span className="text-sm font-medium text-gray-900">
                              No access code specified.
                            </span>
                          )}
                        </p>
                      </div>

                      <Modal
                        isOpen={isAccessCodeModalOpen}
                        onAction={() => {
                          setIsAccessCodeModalOpen(true);
                        }}
                        data={data}
                      >
                        <div className="flex items-center justify-center w-full h-full">
                          <div className="w-full max-w-sm">
                            <p className="text-xl font-bold text-gray-900">
                              {ModifyConfig.ModifyAccessCode.title}
                            </p>
                            <div className="my-6">
                              <label
                                htmlFor=""
                                className="text-sm font-bold text-gray-900"
                              >
                                {ModifyConfig.ModifyAccessCode.label}
                              </label>
                              <div className="mt-2">
                                <input
                                  required={false}
                                  type={FORM.TYPES.TEXT}
                                  name="accessCode"
                                  placeholder=""
                                  defaultValue={enterpriseData.accessCode}
                                  onChange={(e) => {
                                    setAccessCodeValue(e.target.value);
                                  }}
                                  minLength="1"
                                  maxLength="30"
                                  className="block w-full px-4 py-3 placeholder-gray-500 border -gray-300 rounded focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600"
                                />
                              </div>
                            </div>
                            <p className="text-sm text-gray-900 my-6">
                              This must be an alphanumeric code. Special
                              characters are not accepted.
                            </p>
                            <div className="mt-8 space-x-5">
                              <div className="flex items-center justify-end mt-5 space-x-4">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setIsAccessCodeModalOpen(false);
                                  }}
                                  className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  onClick={handleAccessCodeSubmit}
                                  className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-1 lg:col-span-1">
                    <div className="bg-white border border-gray-200 rounded">
                      <div className="px-5 py-4">
                        <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                          Enterprise Portal Link
                        </p>
                        <div className="flex items-center justify-between mt-3">
                          <a
                            href={
                              EnterprisePortal.TEST +
                              enterpriseData.urlCompanyName +
                              `/` +
                              enterpriseData.accessCode
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cursor-pointer text-sm font-bold text-gray-900"
                          >
                            {EnterprisePortal.TEST}
                            {enterpriseData.urlCompanyName
                              ? enterpriseData.urlCompanyName + `/`
                              : ""}
                            {enterpriseData.accessCode
                              ? enterpriseData.accessCode
                              : ""}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="py-11">
          <div className="px-6 mx-auto">
            <Table
              title={`${companyName} licenses`}
              columns={columns}
              data={data}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Licenses;
