import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBH6WccwsfYxqD54znfZUl0TSEWT3iQ6Rw",
  authDomain: "vodium-dev.firebaseapp.com",
  projectId: "vodium-dev",
  storageBucket: "vodium-test.appspot.com",
  messagingSenderId: "328115741898",
  appId: "1:328115741898:web:bb2843f6847998d47b4033",
};
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
